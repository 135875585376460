/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-10-01",
    versionChannel: "nightly",
    buildDate: "2024-10-01T00:06:29.875Z",
    commitHash: "7bdb576f69ee672d41e318d23747c627a586ef64",
};
